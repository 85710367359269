<template>
  <div v-if="event.id">
    <h3>{{ event.title }} </h3>
    <p>{{ event.summary }}</p>
    <b-button-group v-if="isDirector" class="mb-2">
    <b-btn 
      size="sm" 
      variant="success" 
      :to="{ name: 'eventRegistrationList', params: { eid: event.id } }"
    >{{ event.registrations.length }} participants</b-btn> <EventParticipantsExport :eid="event.id" eClass="btn btn-light btn-sm d-print-none" />
    </b-button-group>
    <b-table stacked :items="detailsArr" />
    <p v-html="event.description.replace(/\n/g, '<br />')"></p>
    <div class="clearfix mb-2">
      <b-btn-group class="float-right">
        <BtnPrint btnClass="" />
        <b-btn v-if="isDirector" variant="warning" :to="{ name: 'eventEdit', params: { eid: event.id }}">Edit Event</b-btn>
        <b-btn v-if="isDirector" variant="success" :to="{ name: 'eventDuplicate', params: { eid: event.id }}">Duplicate Event</b-btn>
        <b-btn v-if="isDirector" variant="danger" @click="onEventArchive(event.id)">Archive Event</b-btn>
      </b-btn-group>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EventParticipantsExport from '@/components/EventParticipantsExport'
const DATE_FORMAT = 'MM/DD/YYYY'
export default {
  name: 'event-details',
  data () {
    return {
      event: {
        registrations: 0
      },
      detailsArr: []
    }
  },
  computed: {
    ...mapGetters([
      'business'
    ]),
    eventTitle () {
      return this.event.title + ' <small>(12 Participants)</small>'
    }
  },
  methods: {
    ...mapActions([
      'getEvent'
    ]),
    onEventArchive (id) {
      this.eventArchive(id).then(() => {
        this.showSuccess()
        this.doGetEvent()
      })
    },
    setDetailsArr () {
      let detailsArr = {
        'Status': this.event.status.label.toUpperCase(),
        'Location': this.event.location.name,
        'Type': this.event.type.label,
        'Sport': this.event.sport.label,
        'Ages': this.event.ageMin + ' - ' + this.event.ageMax,
        'Post Date': this.$moment(this.event.postDate).format(DATE_FORMAT)
      }
      // registration pricing @FIXME very verbose
      let punorderedPricing = {}, orderedPricing = {}, pricing = ''
      for (let i in this.event.pricing) {
        let key = this.$moment(this.event.pricing[i].datetimeStart.split('T')[0]).format('YYYYMMDD')
        punorderedPricing[key] = [
          (this.event.pricing[i].tierLabel || 'Registration'), 
          this.$moment(this.event.pricing[i].datetimeStart.split('T')[0]).format(DATE_FORMAT) + ': $' + this.event.pricing[i].price
        ]
      }
      Object.keys(punorderedPricing).sort().forEach(function(key) {
        orderedPricing[key] = punorderedPricing[key];
      })
      for (let i in orderedPricing) {
        detailsArr[orderedPricing[i][0]] = orderedPricing[i][1]
      }
      detailsArr['Registration Ends'] = this.$moment(this.event.registrationEnds).format(DATE_FORMAT)
      // start date & length
      detailsArr['Event Begins'] = this.$moment(this.event.startDate).format(DATE_FORMAT)
      detailsArr['Length'] = this.event.numWeeks + ' weeks'
      // questions
      let questions = '', del = ''
      for (let i in this.event.questions) {
        questions += del + this.event.questions[i].label
        del = ' / '
      }
      detailsArr['Registration Questions'] = questions

      this.detailsArr = [detailsArr]
    },
    doGetEvent () {
      this.getEvent({
        id: this.$route.params.eid,
        business: this.business.id
      }).then(resp => {
        if (!resp) {
          this.$router.push({name: 'events'})
          this.showError({ message: "the event could not be found" })
        }
        this.event = resp
        this.setDetailsArr()
      })
    }
  },
  created () {
    this.doGetEvent()
  },
  components: { EventParticipantsExport }
}
</script>